import React from 'react';
import HTMLParser from 'html-react-parser';

import Carousel from '../../../Carousel';
import { formColor } from '../../../../helper';

import Step from './Step';
import styles from './styles.module.css';

const StepsLayout = ({
  section,
  themeData,
  layout,
  filePathList,
  pagePathList,
  articlePathList,
  images,
  metaLanguage,
  matches,
}) => {
  const { columns, full, align } = section.styles;
  const alignStyle = styles[`align${align}`];

  let cols;
  if (columns === 1 || layout === 'design2') cols = 'col-12';
  else cols = `col-12 col-md-${columns ? 12 / columns : '4'}`;

  const elements = [];
  let steps = [];

  section.data.forEach((item, i) => {
    if (!item.active) return;
    if (['HEADINGS/HEADING-TWO', 'PARAGRAPH/PARAGRAPH'].includes(item.type)) {
      let data;
      if (item.type === 'HEADINGS/HEADING-TWO') {
        data = `<h2>${item.text}</h2>`;
      } else {
        data = `<span>${item.text}</span>`;
      }

      const result = (
        <div key={`${section._id}_layout_${i}`} className={alignStyle}>
          {HTMLParser(data)}
        </div>
      );

      elements.push(result);
    } else if (item.type === 'COMPONENT') {
      const step = (
        <Step
          key={`${section._id}_Step_${i}`}
          component={item}
          id={section._id}
          colors={themeData.colors}
          layout={layout}
          cols={cols}
          i={i}
          images={images}
          pagePathList={pagePathList}
          articlePathList={articlePathList}
          filePathList={filePathList}
        />
      );

      if (step) steps.push(step);
    }
  });

  if (layout === 'design2' && steps.length > 0) {
    const color = formColor({ solid: 'COLOR_0' }, false, 1, undefined, themeData.colors);
    steps = (
      <div className={styles.carouselWrapper}>
        <Carousel
          showIndicators
          customColor={color}
          textnav
          shadow
          l={metaLanguage}
          matches={matches}
          customIndicatorPosition={{ bottom: '40px' }}
        >
          {steps}
        </Carousel>
      </div>
    );
  }

  return (
    <div className={`container ${full ? styles.containerFull : styles.container} ${styles[layout]}`}>
      {layout === 'design1' ? <div className={styles.design1Elements}>{elements}</div> : elements}
      <div className="row">{steps}</div>
    </div>
  );
};

export default StepsLayout;
