import React from 'react';
import clsx from 'clsx';
import HTMLParser from 'html-react-parser';

import SectionMedia from '../../SectionMedia';
import { formColor } from '../../../../../helper';
import styles from '../styles.module.css';

const Step = ({ id, colors, layout, cols, component, i, filePathList, pagePathList, articlePathList, images }) => {
  let step = null;

  const top = [];
  let sub;
  let text;
  component.data.forEach((elem, j) => {
    if (!elem.active) return;
    if (elem.type === 'IMAGES/IMAGE') {
      top.push(
        <SectionMedia
          key={`imageItem_${id}_${j}_${i}`}
          mediaType={elem.content.icon ? 'ICON' : 'IMAGE'}
          wrapperStyleName="steps"
          alt={elem.content.alt}
          data={elem.content}
          images={images}
          pagePathList={pagePathList}
          articlePathList={articlePathList}
          filePathList={filePathList}
          colors={colors}
        />,
      );
    } else {
      let data;

      if (elem.type.startsWith('HEADINGS/')) {
        if (elem.type === 'HEADINGS/HEADING-THREE') data = `<h3>${elem.text}</h3>`;
        else data = `<h4>${elem.text}</h4>`;
      } else data = `<span>${elem.text}</span>`;

      const result = (
        <div key={`${id}_layout_${j}_${i}`} className={styles[`align${component.align}`]}>
          {HTMLParser(data)}
        </div>
      );

      if (elem.type === 'HEADINGS/HEADING-THREE') top.push(result);
      else if (elem.type === 'HEADINGS/HEADING-FOUR') sub = result;
      else text = result;
    }
  });

  if (top.length > 0 || sub || text) {
    let style;
    if (component.styles.backgroundColor.active) {
      let { backgroundColor } = component.styles;
      if (component.styles.backgroundColor.solid === '' && component.styles.backgroundColor.gradient.from === '') {
        backgroundColor = { solid: '#ffffff' };
      }

      style = formColor(backgroundColor, false, component.styles.backgroundColor.opacity, undefined, colors);
    }

    const solid = layout === 'design1' ? 'COLOR_2' : 'COLOR_0';
    const bg = formColor({ solid }, false, 1, undefined, colors);
    const content = (
      <>
        <div
          className={clsx(styles[`align${component.align}`], styles[`top${layout === 'design1' ? '1' : '2'}`])}
          style={layout === 'design1' ? null : bg}
        >
          {top}
        </div>
        {layout === 'design2' ? (
          <div className={styles.design2}>
            <div>{sub}</div>
            <div>{text}</div>
          </div>
        ) : (
          <>
            <div>{sub}</div>
            <div className={styles.line} style={bg} />
            <div>{text}</div>
          </>
        )}
      </>
    );

    step = (
      <div
        key={`${id}_Component_${i}_section`}
        className={clsx(cols, layout === 'design1' ? styles.step1 : styles.step2, styles[`align${component.align}`])}
        style={layout === 'design2' ? style : undefined}
      >
        {layout === 'design1' ? (
          <div className={styles.step1ContentWrapper} style={style}>
            {content}
          </div>
        ) : (
          content
        )}
      </div>
    );
  }

  return step;
};

export default Step;
