import React from 'react';
import HTMLParser from 'html-react-parser';

import { getEmbedUrl } from '../../../../helper';

import './style.css';
import styles from './styles.module.css';

let Lightbox;
if (typeof window !== 'undefined') {

  Lightbox = require('lightbox-react').default;

}

class VideoLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const dataIndex = props.section.data.findIndex(elem => elem.type === 'DATA');

    let videoUrl;
    if (dataIndex > -1 && props.section.data[dataIndex].videoID
    ) videoUrl = getEmbedUrl(props.section.data[dataIndex]);

    this.state = {
      isOpen: false,
      dataIndex,
      videoUrl,
    };

    this.handleClick = this.handleClick.bind(this);

  }

  handleClick(value) {

    this.setState({
      isOpen: value,
    });

  }

  render() {

    const cols = (this.props.layout !== 'box' && this.props.layout !== 'center' && this.props.layout !== 'full') ? 'col-12 col-md-6' : 'col-12';

    let videoBase;
    if (this.state.videoUrl) {

      videoBase = (
        <iframe
          frameBorder="0"
          className={styles.videoIframeStyle}
          src={this.state.videoUrl}
          allowFullScreen
          aria-hidden="true"
        />
      );

    }

    let video;
    let btn;
    if (this.props.layout === 'box') {

      btn = (
        <div className={styles.btnWrapper}>
          <button
            onClick={() => this.handleClick(true)}
            className={styles.btn}
            style={{ backgroundColor: this.props.themeData.colors[0] }}
          >
            <i aria-hidden="true" className={`entypo icon-play ${styles.icon}`} />
            { this.props.section.data[this.state.dataIndex].btnText }
          </button>
        </div>
      );

      if (this.state.isOpen === true && typeof window !== 'undefined') {

        video = (
          <Lightbox
            mainSrc={videoBase}
            onCloseRequest={() => this.handleClick(false)}
          />
        );

      }

    } else {

      video = (
        <div className={`${cols} ${styles.videoFrameWrapper}`}>
          { videoBase }
        </div>
      );

    }

    const texts = [];
    this.props.section.data.forEach((elem, i) => {

      if (elem.active && (elem.type === 'HEADINGS/HEADING-TWO' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        let content;
        if (elem.type === 'HEADINGS/HEADING-TWO') content = `<h2>${elem.text}</h2>`;
        else content = `<span>${elem.text}</span>`;

        const result = (
          <div
            key={`${this.props.section._id}_elem_${i}`}
            className={styles.videoText}
          >
            { HTMLParser(content) }
          </div>
        );

        texts.push(result);

      }

    });

    return (
      <div className={this.props.layout === 'full' ? styles.noPadding : 'container'}>
        <div className={`row no-gutters ${this.props.layout === 'right' ? styles.videoLayoutRight : ''}`}>
          {
            (
              (
                texts.length > 0
                && (this.props.layout === 'center' || this.props.layout === 'full')
              )
              || (this.props.layout !== 'center' && this.props.layout !== 'full')
            )
            && (
              <div className={`${cols} ${styles.videoInnerWrapper}`} style={this.props.boxStyle}>
                <div className={styles.videoInnerInnerWrapper}>
                  { texts }
                  { btn }
                </div>
              </div>
            )
          }
          { video }
        </div>
      </div>
    );

  }

}

export default VideoLayout;
