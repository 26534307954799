import React from 'react';

import styles from './styles.module.css';

const Left = ({
  id,
  compContent,
  index,
  align,
  highlight,
  bg,
  first,
  last,
}) => {

  let radius = '';
  if (!highlight && index === first && last - first > 1) {

    radius = 'comparisonComponentContentLeft';

  } else if (!highlight && index === last && last - first > 1) {

    radius = 'comparisonComponentContentRight';

  }

  const componentStyle = `${styles[`comparisonComponent${highlight ? 'Highlighted' : ''}`]} ${styles[`align${align}`]}`;
  const contentStyle = `${styles[`comparisonComponentContent${highlight ? 'Highlighted' : ''}`]} ${styles[`align${align}`]}${radius !== undefined ? ` ${styles[radius]}` : ''}`;

  return (
    <div
      key={`${id}_layout_${index}`}
      className={`col-xs-12 col-sm-8 col-md-4 ${componentStyle}`}
    >
      <div
        className={contentStyle}
        style={bg !== undefined ? bg : {}}
      >
        { compContent }
      </div>
    </div>
  );

};

export default Left;
