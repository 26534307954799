import React, { useMemo } from 'react';
import HTMLParser from 'html-react-parser';

import { constructLink } from '../../../../helper';
import Image from '../../../Image';
import ContentLink from '../../../ContentLink';

import styles from './styles.module.css';

const LogoGridLayout = ({ section, layout, images, pagePathList, articlePathList, filePathList }) => {
  const { columns, full, align, rowHeight } = section.styles;

  const sizesObj = useMemo(
    () => ({
      default: columns,
      md: columns === 1 ? 1 : 2,
      sm: 1,
    }),
    [columns],
  );
  const sizes = useMemo(
    () =>
      [
        `(max-width: 576px) ${100 / sizesObj.sm}vw`,
        `(min-width: 576px and max-width: 768px) ${100 / sizesObj.md}vw`,
        `(min-width: 768px) ${100 / sizesObj.default}vw`,
      ].join(', '),
    [sizesObj],
  );
  const alignStyle = styles[`align${align}`];

  const textElements = [];
  const imageElements = [];

  section.data.forEach((item, i) => {
    if (!item.active) return;
    if (['HEADINGS/HEADING-TWO', 'PARAGRAPH/PARAGRAPH'].includes(item.type)) {
      textElements.push(
        <div key={`${section._id}_layout_${i}`} className={alignStyle}>
          {HTMLParser(item.type === 'HEADINGS/HEADING-TWO' ? `<h2>${item.text}</h2>` : `<span>${item.text}</span>`)}
        </div>,
      );
    } else if (item.type === 'IMAGES/IMAGE') {
      const { linkObj } = item.content;
      let image = (
        <Image
          id={item.content.id}
          url={item.content.src}
          sizes={sizes}
          alt={item.content.alt}
          className={styles.image}
          images={images}
        />
      );

      if (linkObj && (linkObj.src || linkObj.type)) {
        const key = `${section._id}_LogoGridLink_${i}`;
        const { link, type: linkType } = constructLink(
          linkObj.type ? linkObj : linkObj.src,
          pagePathList,
          articlePathList,
          filePathList,
        );

        image = (
          <ContentLink key={key} openLinkInNewTab={linkObj.openLinkInNewTab} link={link} type={linkType}>
            {image}
          </ContentLink>
        );
      }

      imageElements.push(
        <div
          className={`${styles.imageWrapper} md${sizesObj.md}`}
          style={{ width: `${100 / columns}%` }}
          key={`${section._id}_layout_${i}`}
        >
          <div style={{ position: 'relative', height: rowHeight }}>{image}</div>
        </div>,
      );
    }
  });

  return (
    <div className={`container ${full ? styles.containerFull : styles.container} ${styles[layout]}`}>
      {textElements}
      {!!imageElements.length && <div className={styles.images}>{imageElements}</div>}
    </div>
  );
};

export default LogoGridLayout;
